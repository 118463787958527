.page{
    padding: 2%;
}
.sum{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 2rem;
}
.sum.ant-tag{
    line-height: 1.6;
    
}
.sumdef{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1.3rem;
    padding-top: 1%;
}
@media only screen and (max-width: 768px) {
    .TecSkl{
        padding-top: 1%;
        justify-content: center;
        align-items: center;
        border-bottom: 2px double rgb(145, 213, 255);
        margin-top: 3px;
        margin-bottom: 4px;
        }
}
.TecSkl{
padding-top: 1%;
justify-content: center;
align-items: center;
}
.skills{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 0.9rem;
}
.skills.ant-tag{
    line-height: 1.8;
    margin-bottom: 0.5%
    
}
.tchr{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    padding-top: 2%;
}
.tchr strong {
    font-size: 2rem;
}
.lorimg{
    width: 100%;
}
.para{
    text-align: left;
}
.pokem{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1rem;
}
.padbot{
    padding-bottom: 4%;
}
.butpad{
    padding-bottom: 3%; 
}
.frame1{
    height: 100%;
    background: blue;

}
.frame1 .ant-modal-content{
    height: 90%;
    background: red;
}
@media only screen and (max-width: 767px) {
    .page{
        padding: 2%;
    }
    .sum{
        font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 1.3rem;
    }
    .sum.ant-tag{
        line-height: 1.6;
        
    }
    .sumdef{
        font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 0.9rem;
        padding-top: 1%;
    }
    .TecSkl{
    padding-top: 1%;
    justify-content: center;
    align-items: center;
    }
    .skills{
        font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 0.9rem;
    }
    .skills.ant-tag{
        line-height: 1.5;
        margin-bottom: 0.5%
        
    }
    .tchr{
        font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        padding-top: 4%;
    }
    .tchr strong {
        font-size: 1rem;
    }
    .lorimg{
        width: 100%;
    }
    .para{
        text-align: left;
    }
    .pokem{
        font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 1rem;
    }

}
    

