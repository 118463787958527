.footer {
    padding: 3px;
    background: #1c83c7;
  }
  .App {
    text-align: center;
  }
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height:25vmin;
    pointer-events: none;
  }
  .logopadding {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 1%;
    justify-content: center;
    align-items: center;
  }
  .logopading1{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .textpading{
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 0.9rem;
    color: cyan;
  }
  .footcontent{
    padding: 3%;
  }
  
  .footcon li{
    display: contents;
    padding-top: 3%;
    
  }
  .footcon a{
    color: cyan;
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1rem;
  }
  .footlogo{
    padding: 3%;
    
  }
  .buttonlogo{
    background: transparent; 
  }
  
  .buttonlogo.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
    text-decoration: none;
    background: #1c83c7;
}
.buttonlogo.ant-btn:hover, .ant-btn:focus {
    color: #40a9ff;
    background-color: #1c83c7;
    border-color:cyan;
}
  .buttonlogo i {
    justify-content: center;
    display: flex;
    color: cyan;
}
.copyrightcc{
  text-align: center;
}
/* mobile */
@media only screen and (max-width: 767px) {
.footer{
  padding: 0;
}
.imgclasdiv{
padding: 5%;
}
.imgclass{
  width: 100%;
  object-fit: contain;
}
.footcon{
  font-size: 1.5rem;
  font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


}