.page{
    padding: 3%;
}
.macro {
    display: none;
}

.header{
    text-align: center;
    font-size: 3rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.paratext{
    font-size: 1.3rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.curimg{
    width: 100%;
    object-fit: contain;
    height: 300px;
}
.sourcepad{
padding-top: 2%;
font-size: 2rem;
}

@media only screen and (max-width: 767px) {
    .page{
      padding: 3%;
    }
    .header{
        font-size: 2rem;
    }
    .paratext{
        font-size: 1.3rem;
    }
    .sourcepad{
        padding-top: 2%;
        font-size: 1rem;
    }
    .videoimg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    justify-content: center;
    align-items: center;
    }
}
