.sidedrawer{
height: 80%;
background: #cbdaeb;
box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
position: fixed;
top: 0;
right:0;
width: 70%;
z-index: 200;
transform: translateX(100%);
transition: transform 0.3s ease-in-out;
}
.sidedrawer.open {
    transform: translateX(0)
}
.divpadding{
    margin-top: 35%;
    padding: 10%;
    justify-content: center;
    align-items: center;
}
.buttonview{
    padding: 5%;
}
.buttonclass{
    border: none;
    background:#cbdaeb;
    
}
.buttonclass.ant-btn{
    box-shadow: 0; 
}
.socialbadges{
    padding:5%;
    margin-top: 7%;
}



@media only screen and (max-width: 320px) {
    .socialbadges{
      padding: 0;
      width: 100%;
    }
}