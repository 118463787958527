.form1 .ant-form-item .ant-switch {
    margin: 2px 0 4px;
    background: darkorange;
}
.button{
    width: 200px;
    background: #1c83c7;
    
}
.button1{
    width: 200px;
    background: darkorange;
}
