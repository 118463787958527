.poke {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    flex: 1;
}
.pok {
    display: flex;
    flex-direction: column;
    padding: 2%;
}
.textAlign{
    text-align: center;
    align-content: center;
    justify-content: center;
    width: 240;
}
.textAlign .ant-card-cover {
    display: flex;
    justify-content: center;
   
}

.textAlign .ant-card-cover img {
    /* border-radius: 2px 2px 0 0; */
    margin-top: 10px; 
    width: 75px;
    height: 80px;
}
.cardtext{
    text-align: left;
    padding-left: 0;
}
.pagination{
    text-align: center;
    padding-top: 2%;

}
.back{
    /* padding-top: 2%; */
    padding-bottom: 2%;
    font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 0.9rem;
}